import styled from "styled-components";


export const SectionWrapper = styled.div`
  border-radius: 27px;
  background: #fafafa;
  max-width: 815px;
  overflow: hidden;
  margin: 0 auto 18px;
  max-width: 100%;
`;

export const SectionWrapperHeader = styled.h3`
  color: #fff;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.64px;
  background: #ff4119;
  text-align: left;
  padding: 0 40px;
`;

export const SectionContent = styled.div`
  background: #fafafa;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const SectionContentLeft = styled.div`
  background: #292d30;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 34px;
  color: #fff;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.64px;
  max-width: 266px;
  text-align: left;

  ${({ additionalstyles }) => (additionalstyles ? additionalstyles : null)}

  p {
    color: rgba(255, 255, 255, 0.55);
    font-size: 13px;
    font-style: italic;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.52px;
  }

  @media (max-width: 768px) {
    padding: 16px;
    max-width: unset;
  }
`;

export const AgeSectionContentOptions = styled.div`
  flex: 1 1 auto;
  padding: 24px 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 18px 68px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 16px;
  }
`;

export const ChallengesSectionContentOptions = styled.form`
  flex: 1 1 auto;
  padding: 30px 40px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 22px;

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export const UploaderHint = styled.p`
  color: #eb5757;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.13px;
  max-width: 311px;
  text-align: left;
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 100px;
  margin-bottom: 200px;

  @media (max-width: 480px) {
    flex-direction: column;
    margin: 50px 0;
  }
`;
