import { useRef, useState } from "react";
import {
  FormContent,
  XMatrixElm,
  YMatrixElm,
  StyledRadioGroup,
  MatrixStyledRadio,
  Container,
} from "./style";
import { Row, Col, Popover} from "antd";
import ArrowElment from "../shared/arrow";
import { FormButton, BackButton } from "../shared/style";
import { PageTitle } from "../page-title";
import sizeGuide from "../../images/size_guide_all.png";

const SportsAttire = ({ setActiveTab, setFormValues }) => {
  const clothingTypes = ["T-Shirt", "Track Pants", "Jacket", "Bunker Gear"];
  const sizes = ["Sports Attire", 'XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL', '4XL', '5XL'];
  const onlySizes = ['XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL', '4XL', '5XL'];
  const form = useRef();
  const [sizesInputs, setSizesInputs] = useState(null);

  const handleContinue = () => {
    //const entries = form.current.getFieldsValue()
    console.log(sizesInputs);

    if (
      sizesInputs &&
      sizesInputs["T-Shirt"] &&
      sizesInputs["Track Pants"] &&
      sizesInputs["Jacket"] &&
      sizesInputs["Bunker Gear"]
    ) {
      const values = {
        t_shirt_size: sizesInputs["T-Shirt"],
        track_pants_size: sizesInputs["Track Pants"],
        jacket_size: sizesInputs["Jacket"],
        bunker_gear_size: sizesInputs["Bunker Gear"],
      };

      setFormValues(values);
      setActiveTab("4");
    }
  };

  const handleBack = () => {
    setActiveTab("2");
  };

  const setChoiceSizes = (type, { target: { value } }) => {
    setSizesInputs({ ...sizesInputs, ...{ [type]: value } });
  };

  return (
    <Container style={{padding: 0}}>
      <PageTitle
        title="Sports Attire"
        description="Measurements for each size will be provided!"
      />
      <FormContent>
        <div
          style={{
            width: "1240px",
            maxWidth: "100%",
            marginBottom: "100px",
          }}
        >
              <Row justify="center">
                {sizes.map((s, index) => (
                  <Col xs={index === 0 ? 3 : 2} key={s} style={{borderLeft: "1px solid #fff"}}>
                    <XMatrixElm style={{display: "flex",justifyContent: "center", alignItems: "center"}}>{s}{index === 0? <Popover content={<img src={sizeGuide} style={{height: 600, maxWidth: "100%"}} />} title="Size Guide" trigger="click">
                      <svg style={{marginLeft: 4, cursor: "pointer"}} width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#1C274C"></path>
                        </g>
                      </svg>
                    </Popover> : <></>}</XMatrixElm>{" "}
                  </Col>
                ))}
              </Row>
              {clothingTypes.map((ct) => (
                <Row justify="center" key={ct}>
                  <Col xs={3} style={{borderBottom: "1px solid #fff"}}>
                    <YMatrixElm>{ct}</YMatrixElm>
                  </Col>

                  <StyledRadioGroup defaultValue={false} name={`radio_${ct}`}>
                    {onlySizes.map((size) => (
                      <Col
                        key={`${ct}-${size}`}
                        xs={2}
                        style={{backgroundColor: "rgba(255, 65, 25, 0.06)", borderLeft: "1px solid #fff", borderBottom: "1px solid #fff"}}
                      >
                        <MatrixStyledRadio
                          value={size}
                          onChange={(event) => setChoiceSizes(ct, event)}
                        />
                      </Col>
                    ))}
                  </StyledRadioGroup>
                </Row>
              ))}

            <div style={{ height: 50 }} />
            <Row justify="center" style={{ marginTop: "10px" }}>
              <Col xs={24} sm={24} md={12}>
                <BackButton onClick={() => handleBack()}>Back</BackButton>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <FormButton onClick={() => handleContinue()}>
                  Continue <ArrowElment />
                </FormButton>
              </Col>
            </Row>
        </div>
      </FormContent>
    </Container>
  );
};
export default SportsAttire;
