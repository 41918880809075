import styled from "styled-components";
export const Header = styled.h1`
  color: #141414;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 48px;
  font-style: italic;
  font-weight: 700;
  line-height: 56px; /* 116.667% */
  letter-spacing: -1.92px;
`;
export const Description = styled.p`
  color: #7a7986;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 177.778% */
  max-width: 700px;
`;
export const DescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ImageContainer = styled.div`
  max-width: 100%;
`;
export const Image = styled.img`
  width: 90%;
  margin: auto;
  margin-top: 99px;
  margin-bottom: 175px;
  object-fit: cover;
  height: 272px;
  flex-shrink: 0;
  border-radius: 37px;
  @media (max-width: 768px) {
    margin: 50px 20px;
  }
`;
Image.defaultProps = {
  src: "/images/default-image.jpg",
};
