import { SuccessCardTitle } from "./style";
import { SuccessCardText } from "./style";
import { SuccessCard } from "./style";
import { Container } from "./style";

const SucessPage = () => {
  return (
    <Container>
      <SuccessCard>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="86"
          height="86"
          fill="none"
          viewBox="0 0 86 86"
        >
          <g clipPath="url(#clip0_243_3323)">
            <path
              fill="#FF4119"
              d="M43 7.167c19.791 0 35.834 16.042 35.834 35.833 0 19.79-16.043 35.833-35.834 35.833C23.21 78.833 7.167 62.791 7.167 43 7.167 23.21 23.21 7.167 43 7.167zm12.667 22.865L37.93 47.77l-7.597-7.6a3.585 3.585 0 00-6.119 2.531c0 .95.377 1.863 1.049 2.535l9.883 9.883a3.94 3.94 0 005.575 0l20.017-20.017a3.585 3.585 0 10-5.07-5.07z"
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_243_3323">
              <path fill="#fff" d="M0 0H86V86H0z"></path>
            </clipPath>
          </defs>
        </svg>
        <SuccessCardTitle>Registration Successful!</SuccessCardTitle>
        <SuccessCardText style={{textAlign: "justify"}}>
          Congratulations, your registration was successful!
          On behalf of the AFC24 Management Team, thank you for completing the
          registration process. Your application is under review, and you should expect a
          confirmation email within the next 72 hours. We look forward to welcoming all AFC24
          international competitors to Saudi Arabia.
        </SuccessCardText>
        <SuccessCardText style={{padding: "5px 10px",
          borderRadius: "5px",
          background: "#FFEB3B",
          textAlign: "left",
          marginTop: "10px"}}>
          Our working schedule is Sunday-Thursday from 7 a.m. -4 p.m.
        </SuccessCardText>
      </SuccessCard>
    </Container>
  );
};
export default SucessPage;
