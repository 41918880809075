import fireLogo from "../../../images/fire-logo.svg";
import aramco from "../../../images/aramco.png";
import line from "../../../images/bg-red.svg";
import { Avatar, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const data = localStorage.getItem("user_data");
  const userData = data ? JSON.parse(data) : null;
  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: (
        <p
          style={{
            maxWidth: "150px",
            wordWrap: "break-word",
          }}
        >
          Signed as: {userData?.email || ""}
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <button
          className="mt-2 w-full"
          onClick={(e) => {
            e.stopPropagation();
            localStorage.removeItem("user_data");
            navigate("/login");
          }}
        >
          Logout
        </button>
      ),
    },
  ];
  return (
    <nav
      style={{
        padding: "18px 5.5%",
        minHeight: "fit-content",
      }}
      className="bg-[#F5F5F5] h-[60px] lg:h-[116px] w-full flex items-center justify-between"
    >
      <img src={line} alt="red line" className="absolute top-0 left-0" />
      <img
        src={fireLogo}
        alt="fire fither logo"
        className="w-[80px] h-[50px] lg:h-[80px]"
      />
      <div className="flex items-center">
        <img
          src={aramco}
          alt="aramco logo"
          className="w-[104px] h-[32px] lg:h-[44px]  object-contain mr-4"
        />

        <Dropdown menu={{ items }} placement="bottomRight" arrow>
          <Avatar
            style={{
              backgroundColor: "#f56a00",
              verticalAlign: "middle",
              maxWidth: "200px",
            }}
            size="large"
            className="cursor-pointer"
          >
            {userData?.name?.[0]?.toUpperCase()}
          </Avatar>
        </Dropdown>
      </div>
    </nav>
  );
};

export default NavBar;
