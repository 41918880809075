import { Button } from "antd";
import styled from "styled-components";

export const FButton = styled.button`
  display: flex;
  width: 299px;
  height: 55px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 100px;
  background: #ff4119;
  color: #ffffff;
`;
export const Arrow = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 42.5px;
`;
export const FacLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const FormButton = styled(Button)`
  display: inline-flex;
  height: 55px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 100px;
  background: #ff4119;
  color: #fff;
  width: 299px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
  border: 0;
  &:hover {
    color: #fff !important;
    background: #ff4119;
    border: 0;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`;

export const BackButton = styled(Button)`
  background: #f3f3f3;
  display: inline-flex;
  height: 55px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 100px;
  width: 299px;
  color: black;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
  border: 0;
  &:hover {
    color: black !important;
    background: #f3f3f3;
    border: 0;
  }
`;
