import LoginForm from "./LoginForm";
import login from "../../images/login.png";
import line from "../../images/bg-red.svg";
import fireLogo from "../../images/fire-logo.svg";
import aramco from "../../images/aramco.png";
import styled from "styled-components";

const Container = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 100px 0 120px;
  overflow: hidden;
  margin-bottom: 60px;

  @media (max-width: 1280px) {
    padding: 0 60px;
  }

  @media (max-width: 991px) {
    padding: 0;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }
`;

const MainImageContainer = styled.div`
  width: 33.77%;
  flex-shrink: 0;
  img {
    max-width: 100%;
  }

  @media (max-width: 991px) {
    width: 100%;
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      overflow: hidden;
      object-position: 35% 35%;
    }
  }
`;

const FormContainer = styled.div`
  width: 44%;
  overflow: hidden;
  max-width: 538px;
  flex: 1 1 auto;
  margin-top: 17vh;
  margin-right: 10%;

  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    max-width: 100%;
    padding: 24px;
  }
`;

const LogoContainer = styled.div`
  img {
    max-width: 100%;
  }
`;

const LoginPage = () => {
  return (
    <main>
      <Container>
        <PageWrapper>
          <img
            src={line}
            alt="red line"
            className="absolute top-0 left-0 z-50"
          />
          <FormContainer>
            <div className="flex items-center gap-[32px]">
              <LogoContainer>
                <img src={fireLogo} alt="aramco logo" />
              </LogoContainer>
              <LogoContainer>
                <img src={aramco} alt="fire fither logo" width={300} />
              </LogoContainer>
            </div>
            <LoginForm />
          </FormContainer>
          <MainImageContainer>
            <img src={login} alt="Firefighter" className="main-img" />
          </MainImageContainer>
        </PageWrapper>
      </Container>
    </main>
  );
};

export default LoginPage;
