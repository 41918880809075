import { Arrow } from "./style";

const ArrowElment = () => {
  return (
    <Arrow>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <path
          d="M19.75 12.2257L4.75 12.2257"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.7002 6.20131L19.7502 12.2253L13.7002 18.2503"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Arrow>
  );
};

export default ArrowElment;
