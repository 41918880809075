import { RegistrationFormHero } from "../register/style";

export const Facebook = () => {
  return (
    <img
      src="/images/Facebook.png"
      alt=""
      width="38px"
      height="38px"
      style={{ borderRadius: "50%", marginLeft: "10px" }}
    />
  );
};
export const Twitter = () => {
  return <img src="/images/twitter-x.png" alt="" width="40px" height="40px" />;
};
export const Instagram = () => {
  return (
    <img
      src="/images/instagram.png"
      alt=""
      width="38px"
      height="38px"
      style={{
        backgroundColor: "#FF4119",
        borderRadius: "50%",
        marginRight: "10px",
      }}
    />
  );
};
export const AramcoLog = () => {
  return (
    <img
      src="/images/aramco.png"
      alt=""
      width="104px"
      height="44px"
      style={{ margin: "18px 80px" }}
    />
  );
};
export const Logo = () => {
  return (
    <img
      src="/images/logo.png"
      alt=""
      width="80px"
      height="80px"
      style={{ margin: "18px 80px" }}
    />
  );
};
export const RegisterForm = () => {
  return (
    <RegistrationFormHero>
      <h2>Registration Form</h2>
      <img
        src="/images/welcome.jpg"
        alt=""
        width="80px"
        height="80px"
        style={{
          borderRadius: "51px 0px 0px 51px",
          width: "calc(100% - 16px)",
          height: "221px",
          marginLeft: "16px",
          overflow: "hidden",
          objectFit: "cover",
        }}
      />
    </RegistrationFormHero>
  );
};
