import styled from "styled-components";

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

export const SuccessCard = styled.div`
  border-radius: 27px;
  background: #fafafa;
  display: flex;
  width: 940px;
  max-width: 100%;
  padding: 58px 172px 57.243px 172px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;

  @media (max-width: 1280px) {
    padding: 48px;
  }

  @media (max-width: 768px) {
    padding: 48px 24px;
  }
`;

export const SuccessCardTitle = styled.h1`
  color: #141414;
  text-align: center;
  font-size: 32px;
  font-style: italic;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1.28px;
`;

export const SuccessCardText = styled.p`
  color: #7a7986;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
`;
