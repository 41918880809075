import { Fragment, useEffect, useRef, useState } from "react";
import { RegisterForm } from "../icons";
import { message } from "antd";
import CompetitorInformation from "./competitor-information";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Visa from "./visa";
import FirstServiceInformation from "./first-service-information";
import SportsAttire from "./sports-attire";
import axios from "axios";
import FireFighter from "./FireFighter";
import { StyledTabs } from "./style";
import { useNavigate, Navigate } from "react-router-dom";

const Register = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [publicAge, setPublicAge] = useState(0);
  const [formValues, setFormValues] = useState({});
  const navigate = useNavigate();
  const tabsRef = useRef(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const saveForm = (entries) => {
    setFormValues({ ...formValues, ...entries });
  };

  useEffect(() => {
    tabsRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, [activeTab]);

  const onFinish = () => {
    let isLoggedIn = JSON.parse(localStorage.getItem("user_data"));
    if (!isLoggedIn?.access_token) {
      return <Navigate to={"/login"} replace={true} />;
    }

    console.log("All values:", formValues);

    let formData = new FormData();
    Object.keys(formValues).forEach((key) => {
      formData.append(key, formValues[key]);
    });

    axios.post("https://intreg-api.afc2024.com/api/competitors", formData, {
        headers: { Authorization: `Bearer ${isLoggedIn.access_token}` },
      }).then((res) => {
        localStorage.removeItem("user_data");
        navigate("/success");
      }).catch((error) => {
        for (let i in error.response.data) {
          if (typeof error.response.data[i] !== "string") {
            error.response.data[i].forEach((errormsg) => {
              //message.error(errormsg);
              message.open({
                type: 'error',
                content: errormsg,
                duration: 10,
              });
            });
          } else {
            //message.error(error.response.data[i]);
            message.open({
              type: 'error',
              content: error.response.data[i],
              duration: 10,
            });
          }
        }
        //message.error("please enter all required inputs!");
        console.log("err", error);
        setFormSubmitted(false)
      }).finally(() => {
        setFormSubmitted(false)
      });
  };

  useEffect(() => {
    if (formSubmitted) {
      onFinish();
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [formSubmitted]);

  const items = [
    {
      label: "Competitor Information",
      children: (
        <CompetitorInformation
          setActiveTab={setActiveTab}
          setFormValues={saveForm}
          setPublicAge={setPublicAge}
        />
      ),
    },
    {
      label: "First Service Information",
      children: (
        <FirstServiceInformation
          setActiveTab={setActiveTab}
          setFormValues={saveForm}
        />
      ),
    },
    {
      label: "Sports Attire",
      children: (
        <SportsAttire setActiveTab={setActiveTab} setFormValues={saveForm} />
      ),
    },
    {
      label: "Firefighter Challenges",
      children: (
        <FireFighter
          setActiveTab={setActiveTab}
          setFormValues={saveForm}
          publicAge={publicAge}
        />
      ),
    },
    {
      label: "Visa",
      children: (
        <Visa
          setActiveTab={setActiveTab}
          setFormValues={saveForm}
          setFormSubmitted={setFormSubmitted}
          formValues={formValues}
          activeTab={activeTab}
        />
      ),
    },
  ];

  let isLoggedIn = JSON.parse(localStorage.getItem("user_data"));
  if (!isLoggedIn?.access_token) {
    return <Navigate to={"/login"} replace={true} />;
  }

  return (
    <Fragment>
      {formSubmitted && (
        <div className="loader-cont">
          <div className="loader"></div>
        </div>
      )}
      <Header />
      <RegisterForm />
      <div ref={tabsRef}>
        <StyledTabs
          activeKey={activeTab}
          tabBarStyle={{
            height: "98px",
            width: "100%",
            color: "#EDEDED",
          }}
          tabBarExtraContent={{ style: { borderBottom: "2px solid #FF4119" } }}
          items={items.map(({ label, children }, i) => {
            const id = String(i + 1);
            return {
              label: label,
              key: id,
              children: children,
            };
          })}
        />
      </div>
      <Footer />
    </Fragment>
  );
};
export default Register;
