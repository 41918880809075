import {useRef, useState} from "react";
import { Container, Label, StyledRadio } from "./style";
import { Form, Radio, Row, Input, Col } from "antd";
import { FormButton, BackButton } from "../shared/style";
import ArrowElment from "../shared/arrow";
import { PageTitle } from "../page-title";

const FirstServiceInformation = ({ setActiveTab, setFormValues }) => {
  const form = useRef();
  const [selectedValue, setSelectedValue] = useState(null);

  const handleContinue = () => {
    const entries = form.current.getFieldsValue();

    const values = {
      firefighter_status: entries.firefighter_status,
      fire_service_name: entries.fire_service_name,
      job_title: entries.job_title,
      firefighter_status_other: entries.firefighter_status_other ?? "",
    };

    setFormValues(values);
    setActiveTab("3");
  };

  const handleBack = () => {
    setActiveTab("1");
  };

  const handleRadioChange = e => {
    setSelectedValue(e.target.value);
  };

  return (
    <Container>
      <PageTitle
        title="Fire Service Information"
        description="Please fill the fields carefully"
      />
      <div style={{ maxWidth: "100%", width: "815px", margin: "10px auto" }}>
        <Form ref={form}>
          <Label>Firefighter Status</Label>

          <Form.Item name="firefighter_status">
            <Radio.Group name="radiogroup" onChange={handleRadioChange}>
              <Row>
                <Col xs={24} sm={8} md={5} lg={5} xl={7}>
                  <StyledRadio value="FullTime">Full Time</StyledRadio>
                </Col>
                <Col xs={24} sm={8} md={5} lg={5} xl={7}>
                  <StyledRadio value="PartTime">Part Time</StyledRadio>
                </Col>
                <Col xs={24} sm={8} md={5} lg={5} xl={7}>
                  <StyledRadio value="Volunteer">Volunteer</StyledRadio>
                </Col>
                <Col xs={24} sm={8} md={5} lg={5} xl={7}>
                  <StyledRadio value="Retired">Retired</StyledRadio>
                </Col>
                <Col xs={24} sm={8} md={5} lg={5} xl={7}>
                  <StyledRadio value="Other">Other</StyledRadio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
          {selectedValue === "Other" ?
          <Form.Item name="firefighter_status_other">
            <Input
              placeholder="If other, please specify"
              style={{
                height: "54px",
              }}
            />
          </Form.Item> : <></>}
          <Form.Item
            name="fire_service_name"
            style={{ marginTop: "30px", marginRight: "10px" }}
            rules={[
              {
                required: true,
                message: "Please enter Name of the fire service you are employed by.",
              },
              { max: 50, message: "Maximum 50 characters allowed." },
            ]}
          >
            <Input
              placeholder="Name of the fire service you are employed by"
              style={{
                height: "54px",
              }}
            />
          </Form.Item>
          <Form.Item
            name="job_title"
            style={{ marginTop: "30px", marginRight: "10px" }}
            rules={[
              {
                required: true,
                message: "Please enter your Job Title ",
              },
              { max: 50, message: "Maximum 50 characters allowed." },
            ]}
          >
            <Input
              placeholder="Job Title"
              style={{
                height: "54px",
              }}
            />
          </Form.Item>
          <Row justify="center" className="mt-[36px]">
            <Col xs={24} sm={24} md={12}>
              <BackButton onClick={() => handleBack()}>Back</BackButton>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <FormButton onClick={() => handleContinue()}>
                Continue <ArrowElment />
              </FormButton>
            </Col>
          </Row>
        </Form>
      </div>
      <div style={{ height: 50 }} />
    </Container>
  );
};
export default FirstServiceInformation;
