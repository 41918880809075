import "./visa.css";
import {Button, DatePicker, Form, Input, InputNumber, message, Radio, Select, Space, Upload} from "antd";
import { useEffect, useRef, useState } from "react";
import { Option } from "antd/es/mentions";
import dayjs from "dayjs";
import { PageTitle } from "../page-title";
import { Container } from "./style";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

const Visa = ({setActiveTab, setFormValues, setFormSubmitted, formValues, activeTab}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [passport, setPassport] = useState(null);
  const [deptCred, setDeptCred] = useState(null);
  const finish = useRef(false);
  const form = useRef();
  const [visa, setVisa] = useState(null);
  const [familyFields, setSelectedValue] = useState(false);

  const handleChangePassport = (info) => {
    if (info.file.status === "done") {
      setPassport(info.file.originFileObj);
    }
  };

  const handleChangeDeptCred = (info) => {
    if (info.file.status === "done") {
      setDeptCred(info.file.originFileObj);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/PDF file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleBack = () => {
    setActiveTab("4");
  };

  const dummyRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleContinue = () => {
    if(! visa) {
      message.error("Must provide a copy of your Saudi eVisa!");
      return
    }
    const entries = form.current.getFieldsValue();
    const values = {
      hotel_rooms: entries.hotel_rooms ?? null,
      family: entries.family ? JSON.stringify(entries.family) : null,
      airport_of_departure: entries.airport_of_departure,
      date_of_departure: entries.date_of_departure?.format("YYYY-MM-DD"),
      date_of_leave: entries.date_of_leave?.format("YYYY-MM-DD"),
      passport: passport,
      fire_dept_credentials: deptCred,
      evisa: visa,
    };
    finish.current = true;
    setFormValues(values);
  };

  useEffect(() => {
    if (finish.current && activeTab === "5") {
      setFormSubmitted(true);
    } else {
      finish.current = false;
    }
  }, [formValues]);

  const disabledDate = (current) => {
    // Disable dates before today (past dates)
    return current && current < dayjs("02/29/2024");
  };

  const disabledDateMarch = (current) => {
    // Disable dates before today (past dates)
    return current && current < dayjs("03/01/2024");
  };

  const handleRadioChange = e => {
    setSelectedValue(e.target.value);
  };

  const beforeUploadVisa = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/PDF file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChangeVisa = (info) => {
    if (info.file.status === "done") {
      setVisa(info.file.originFileObj);
    }
  };

  const fetchAirports = async (value) => {
    setSearchTerm(value);
    setLoading(true);

    // Replace 'YOUR_API_KEY' with your actual API key
    const apiKey = "b3d2d252df44af20f3b34396038ad502";
    const apiUrl = `https://api.aviationstack.com/v1/airports?access_key=${apiKey}&search=${value}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      // Extract the airport options from the API response
      const airportOptions = data.data.map((airport) => ({
        value: airport.iata_code,
        label: `${airport.airport_name} (${airport.iata_code})`,
      }));

      setOptions(airportOptions);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching airport data:", error);
      setLoading(false);
    }
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <main className="m-auto">
      <Container>
        <PageTitle
          title="Visa"
          description="Please fill the fields carefully"
        />
        <Form ref={form}>
          <section className="Logistics">
            <h3>Travel Logistics</h3>
            <li>
              Outbound Flight Information from home country
              <span> (from 29 February, 2024)</span>
            </li>
            <section className="date-par margin-33">
              <div className="date">
                <div style={{width: "100%"}}>
                  <h4>Date of Departure</h4>
                  <Form.Item
                    name="date_of_departure"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your date of departure.",
                      },
                    ]}
                    className="m-0"
                  >
                    <DatePicker
                      style={{width: "100%"}}
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="date">
                <div style={{width: "100%"}}>
                  <h4>Airport of Departure</h4>
                  <Form.Item
                    name="airport_of_departure"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your airport of departure.",
                      },
                    ]}
                    className="m-0"
                  >
                    <Select
                      showSearch
                      placeholder="Search for an airport"
                      optionFilterProp="children"
                      onSearch={fetchAirports}
                      style={{width: "100%"}}
                      loading={loading}
                    >
                      {options.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </section>
            <li>
              Return Fight Information <span>from Dammam, Saudi Arabia</span>
            </li>

            <section className="date-par">
              <div className="date">
                <div style={{width: "100%"}}>
                  <h4>Date of Departure</h4>
                  <Form.Item
                    name="date_of_leave"
                    rules={[
                      {
                        required: false,
                        message: "Please enter your date of departure.",
                      },
                    ]}
                    className="m-0"
                  >
                    <DatePicker
                      style={{width: "100%"}}
                      disabledDate={disabledDateMarch}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="date">
                <div style={{width: "100%"}}>
                  <h4>Airport of Departure</h4>
                  <Select
                    style={{width: "100%"}}
                    placeholder="Select an Airport"
                    defaultValue={"1"}
                    disabled
                    options={[
                      {
                        value: "1",
                        label:
                          "King Fahd International Airport Dammam, Saudi Arabia",
                      },
                    ]}
                  />
                </div>
              </div>
              <p>Aramco will cover competitor flights and accommodation from February 29 to March 10, 2024. If a
                competitor wishes to extend their stay in Saudi Arabia, the AFC24 management team can extend the
                departure flight date to a date of your choice, however accommodation after March 10 is at the competitor’s
                expense, and Aramco will not be liable for competitors after March 10.</p>
            </section>
          </section>

          <section className="upload-par" style={{marginBottom: 40}}>
            <Form.Item label="Are there any companions attending" name="experiece" onChange={handleRadioChange}>
              <Radio.Group>
                <Radio.Button value="yes">Yes</Radio.Button>
                <Radio.Button value="no">No</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {familyFields === "yes" ?
              <>
                <p className="mb-4">Please provide their details:</p>
                <Form.List name="family" initialValue={[
                  {first_name: "", family_name: "", relationship: null},
                ]}>
                  {(fields, {add, remove}) => (
                    <>
                      {fields.map(({key, name, ...restField}) => (
                        <Space
                          key={key}
                          style={{display: 'flex', marginBottom: 8}} align="baseline">
                          <Form.Item
                            {...restField}
                            name={[name, 'first_name']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing first name',
                              },
                            ]}
                          >
                            <Input placeholder="First name"/>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'family_name']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing family name',
                              },
                            ]}
                          >
                            <Input placeholder="Family name"/>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'relationship']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing relationship',
                              },
                            ]}
                            style={{width: "250px"}}
                          >
                            <Select
                              placeholder="Relationship to you"
                              style={{
                                width: 250,
                              }}
                              options={[
                                {value: 'Spouse', label: 'Spouse'},
                                {value: 'Parent', label: 'Parent'},
                                {value: 'Child', label: 'Child'},
                                {value: 'Coach', label: 'Coach'},
                                {value: 'Physio', label: 'Physio'},
                                {value: 'Friend', label: 'Friend'},
                              ]}
                            />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)}/>
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                          Add More
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Form.Item label="Do you require any additional rooms" name="hotel_rooms" rules={[{required: true, message: 'Missing enter number of rooms'}]}>
                  <InputNumber />
                </Form.Item>
                <p style={{color: "#eb5757"}}>Aramco will cover only the cost of the competitors double room.</p>
              </>
              : <></>}
          </section>

          <section className="upload-par">
            <h3>
              Scanned documents: Please attach a scanned copy of the following documents
              </h3>
              <Upload
                accept=".png,.jpeg,.jpg,application/pdf"
                listType="picture"
                customRequest={dummyRequest}
                beforeUpload={beforeUploadVisa}
                onChange={handleChangeVisa}
                multiple={false}
                maxCount={1}
              >
                <section className="uploadInput margin-29">
                  <span>Saudi eVisa</span>
                  <div>
                    Upload your eVisa
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="23"
                      viewBox="0 0 24 23"
                      fill="none"
                    >
                      <g opacity="0.8">
                        <path
                          d="M4 15.8607V17.7267C4 18.2216 4.21071 18.6962 4.58579 19.0461C4.96086 19.3961 5.46957 19.5927 6 19.5927H18C18.5304 19.5927 19.0391 19.3961 19.4142 19.0461C19.7893 18.6962 20 18.2216 20 17.7267V15.8607M7 8.39686L12 3.73193M12 3.73193L17 8.39686M12 3.73193V14.9278"
                          stroke="#FF4119"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </div>
                </section>
              </Upload>
              <Upload
                accept=".png,.jpeg,.jpg,application/pdf"
                listType="picture"
                customRequest={dummyRequest}
                beforeUpload={beforeUpload}
                onChange={handleChangePassport}
                multiple={false}
                maxCount={1}
              >
                <section className="uploadInput margin-45">
                  <span>Passport</span>
                  <div>
                    Upload your Passport details
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="23"
                      viewBox="0 0 24 23"
                      fill="none"
                    >
                      <g opacity="0.8">
                        <path
                          d="M4 15.8607V17.7267C4 18.2216 4.21071 18.6962 4.58579 19.0461C4.96086 19.3961 5.46957 19.5927 6 19.5927H18C18.5304 19.5927 19.0391 19.3961 19.4142 19.0461C19.7893 18.6962 20 18.2216 20 17.7267V15.8607M7 8.39686L12 3.73193M12 3.73193L17 8.39686M12 3.73193V14.9278"
                          stroke="#FF4119"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </div>
                </section>
                <p className="m-5" style={{color: "#eb5757"}}>Please ensure that your passport is valid for at least six months from the date of your arrival in KSA.</p>
              </Upload>
              <Upload
                accept=".png,.jpeg,.jpg,application/pdf"
                listType="picture"
                customRequest={dummyRequest}
                beforeUpload={beforeUpload}
                onChange={handleChangeDeptCred}
                multiple={false}
                maxCount={1}
              >
                <section className="uploadInput margin-29">
                  <span>Fire department credentials</span>
                  <div>
                    Upload your fire department ID or letter of employment
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="23"
                      viewBox="0 0 24 23"
                      fill="none"
                    >
                      <g opacity="0.8">
                        <path
                          d="M4 15.8607V17.7267C4 18.2216 4.21071 18.6962 4.58579 19.0461C4.96086 19.3961 5.46957 19.5927 6 19.5927H18C18.5304 19.5927 19.0391 19.3961 19.4142 19.0461C19.7893 18.6962 20 18.2216 20 17.7267V15.8607M7 8.39686L12 3.73193M12 3.73193L17 8.39686M12 3.73193V14.9278"
                          stroke="#FF4119"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </div>
                </section>
              </Upload>
            </section>
            <section className="btns">
              <button onClick={() => handleBack()}>Back</button>
              <button onClick={() => handleContinue()}>
                Finish
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    d="M20.25 12.2256L5.25 12.2256"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.2002 6.20124L20.2502 12.2252L14.2002 18.2502"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </section>
        </Form>
      </Container>
    </main>
);
};

export default Visa;
