import { Radio, Tabs } from "antd";
import styled from "styled-components";

export const StyledTabs = styled(Tabs)`
  &:where(.css-dev-only-do-not-override-gzal6t).ant-tabs
    .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    color: #ff4119;
    border-color: #ff4119 !important;
  }
  &:where(.css-dev-only-do-not-override-gzal6t).ant-tabs .ant-tabs-tab:hover {
    color: #ff4119;
  }
  .ant-tabs-nav-list {
    margin: 0 auto;
    .ant-tabs-tab:first-of-type,
    .ant-tabs-tab:last-of-type {
      padding: 0 16px !important;
    }
  }
`;
export const TabsHeader = styled.div`
  color: var(--Style, #141414);
  text-align: center;
  font-family: Montserrat;
  font-size: 48px;
  font-style: italic;
  font-weight: 700;
  line-height: 56px; /* 116.667% */
  letter-spacing: -1.92px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const TabsTotes = styled.p`
  color: #7a7986;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 177.778% */
`;
export const ImageNotes = styled.p`
  color: #ff4119;
  text-align: center;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 32px;
  margin: 38px 0;
`;
export const FormContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .avatar-uploader {
    .ant-upload {
      width: 98px !important;
      height: 98px !important;
      margin: 0 !important;
    }
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const StyledRadio = styled(Radio)`
  height: 65px;
  width: 150px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid #eee;
  align-items: center;
  padding-left: 27px;
  margin-right: 100px;
  margin-bottom: 20px;
  &:where(.css-dev-only-do-not-override-gzal6t).ant-radio-wrapper
    .ant-radio-checked
    .ant-radio-inner {
    border: 1px solid #ff4119; /* Change border color */
    background-color: #ff4119; /* Change fill color */
  }
  @media (max-width: 575px) {
    width: 100%;
  }
`;

export const CheckMark = styled.span`
  margin-left: 10px;
  color: red;
  visibility: hidden;
`;
export const Label = styled.p`
  color: var(--Gray-3, #828282);
  font-family: Montserrat;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 141.176% */
  letter-spacing: 0.17px;
  text-align: left;
  margin-bottom: 20px;
`;

export const XMatrixElm = styled.div`
  height: 69px;
  flex-shrink: 0;
  background-color: #ff4119;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  line-height: 56px; /* 350% */
  letter-spacing: -0.64px;
`;
export const YMatrixElm = styled.div`
  height: 69px;
  flex-shrink: 0;
  background-color: #292d30;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 56px; /* 350% */
  letter-spacing: -0.64px;
`;
export const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
  display: contents;
  align-items: center;
  background-color: rgba(255, 65, 25, 0.06);
  justify-content: center;
`;
export const RadiosGroups = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 65, 25, 0.06);
  justify-content: center;
`;

export const MatrixStyledRadio = styled(Radio)`
  flex-shrink: 0;
  margin-top: 18px;
  align-items: center;
  &:where(.css-dev-only-do-not-override-gzal6t).ant-radio-wrapper
    .ant-radio-checked
    .ant-radio-inner {
    border: 1px solid #ff4119; /* Change border color */
    background-color: #ff4119; /* Change fill color */
  }
`;

export const Container = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 164px;
  overflow: hidden;
  @media (max-width: 992px) {
    padding: 0 16px;
  }
`;

export const RegistrationFormHero = styled.div`
  position: relative;
  color: #fff;
  font-size: 38px;
  font-weight: 600;
  line-height: 50px;
  h2 {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
  }
`;
