import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import HomePage from "./components/home";
import RegisterPage from "./components/register";
import LoginPage from "./components/login/LoginPage";
import SucessPage from "./components/success-page";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* <Route path="/" element={<Navigate replace to="/" />} /> */}

          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/success" element={<SucessPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
