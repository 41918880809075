import {useEffect, useMemo, useRef, useState} from "react";
import { ImageNotes, FormContent, Container } from "./style";
import {
  Form,
  Upload,
  message,
  Input,
  DatePicker,
  Select,
  Row,
  Col, Radio, Space, Button,
} from "antd";
import {LoadingOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import { FormButton } from "../shared/style";
import ArrowElment from "../shared/arrow";
import PhoneInput from "antd-phone-input";
import FormItem from "antd/es/form/FormItem";
import { PageTitle } from "../page-title";
import dayjs from "dayjs";
import countryList from 'react-select-country-list'

const { Option } = Select;

const validator = (_, { valid }) => {
  // if (valid(true)) return Promise.resolve(); // strict validation
  if (valid()) return Promise.resolve(); // non-strict validation
  return Promise.reject("Invalid phone number");
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CompetitorInformation = ({
  setActiveTab,
  setFormValues,
  setPublicAge,
}) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [photoObj, setPhotoObj] = useState(null);
  const [age, setAge] = useState(0);
  const [experienceFields, setSelectedValue] = useState(false);
  const options = useMemo(() => countryList().getData().filter((country) => { return ! ["IL", "RU", "IR"].includes(country.value) }), [])

  const form = useRef();

  useEffect(() => {
    // Set the initial value for the form field
    form.current.setFieldsValue({ age: age });
    setPublicAge(age);
  }, [age]);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
        setPhotoObj(info.file.originFileObj);
      });
    }
  };

  const handleRadioChange = e => {
    setSelectedValue(e.target.value);
  };

  const disabledDate = (current) => {
    // Disable dates before today (past dates)
    return current && current > dayjs().endOf("day");
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const onFinish = (values) => {
    setActiveTab("2");
  };

  const handleContinue = () => {
    //console.log("Received values:", form.current.getFieldsValue());
    if(! photoObj) {
      message.error("Must upload your personal photo!");
      return
    }

    form.current.validateFields().then(() => {
      const entries = form.current.getFieldsValue();

      const values = {
        first_name: entries.first_name,
        last_name: entries.last_name,
        date_of_birth: entries.date_of_birth?.format("YYYY-MM-DD"),
        current_age: entries.age,
        gender: entries.gender,
        address: entries.address,
        city: entries.city,
        nationality: entries.nationality,
        country: entries.country,
        mobile: entries.mobile.countryCode + entries.mobile.phoneNumber,
        email: entries.email,
        music: entries.music ?? "",
        photo: photoObj,
        bio: entries.bio ? JSON.stringify(entries.bio) : null,
      };
      setFormValues(values);
      setActiveTab("2");
    });
  };

  const validateDateOfBirth = (_, value) => {
    const age = calculateAge(value);
    setAge(age);
    if (age < 18) {
      return Promise.reject("Must be at least 18 years old.");
    }
    return Promise.resolve();
  };

  const dummyRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  return (
    <Container>
      <PageTitle
        title="Competitor Information"
        description="Please fill the fields carefully"
      />
      <div style={{ height: 30 }} />
      <FormContent>
        <div style={{ width: "815px", maxWidth: "100%" }}>
          <Upload
            accept=".png,.jpeg,.jpg"
            name="avatar"
            listType="picture-circle"
            className="avatar-uploader"
            showUploadList={false}
            customRequest={dummyRequest}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            multiple={false}
            maxCount={1}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{
                  width: "100%",
                  borderRadius: "50%",
                  height: 102,
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
          <ImageNotes>
            This photo will be used as your competitor ID
          </ImageNotes>
          <Form ref={form} name="myForm" onFinish={onFinish}>
            <Row gutter={54}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your first name.",
                    },
                    {max: 50, message: "Maximum 50 characters allowed."},
                  ]}
                  style={{marginRight: "5px"}}
                >
                  <Input
                    placeholder="First Name"
                    style={{height: "54px"}}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="last_name"
                  rules={[
                    {required: true, message: "Please enter your last name."},
                    {max: 50, message: "Maximum 50 characters allowed."},
                  ]}
                >
                  <Input placeholder="Last Name" style={{height: "54px"}}/>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email address.",
                },
                {
                  type: "email",
                  message: "Please enter a valid email address.",
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Email Address"
                style={{height: "54px"}}
              />
            </Form.Item>

            <FormItem name="mobile" rules={[{validator}]}>
              <PhoneInput enableSearch style={{height: "54px"}}/>
            </FormItem>

            <Form.Item
              name="date_of_birth"
              rules={[
                {
                  required: true,
                  message: "Please enter your date of birth.",
                },
                {validator: validateDateOfBirth},
              ]}
            >
              <DatePicker
                style={{width: "100%", height: "54px"}}
                placeholder="Date of Birth"
                disabledDate={disabledDate}
              />
            </Form.Item>

            <Form.Item
              name="age"
              rules={[{required: true, message: "Please enter your age."}]}
            >
              <Input
                type="number"
                min={0}
                placeholder="Age"
                value={age}
                disabled
                style={{height: "54px"}}
              />
            </Form.Item>

            <Form.Item
              name="gender"
              rules={[
                {required: true, message: "Please select your gender."},
              ]}
              style={{textAlign: "left"}}
            >
              <Select placeholder="Select a Gender" style={{height: "54px"}}>
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="nationality"
              rules={[
                {required: true, message: "Please select your nationality."},
              ]}
              style={{textAlign: "left"}}
            >
              <Select
                showSearch
                placeholder="Nationality"
                style={{height: "54px"}}
                options={options}
                optionFilterProp="label"
              />
            </Form.Item>

            <Form.Item
              name="country"
              rules={[
                {required: true, message: "Please select your country."},
              ]}
              style={{textAlign: "left"}}
            >
              <Select
                showSearch
                placeholder="Country of Residence"
                style={{height: "54px"}}
                options={options}
                optionFilterProp="label"
              />
            </Form.Item>

            <Form.Item
              name="address"
              rules={[
                {required: true, message: "Please enter your address."},
                {max: 255, message: "Maximum 255 characters allowed."},
              ]}
            >
              <Input.TextArea placeholder="Address"/>
            </Form.Item>

            <Form.Item
              name="city"
              rules={[
                {required: true, message: "Please enter your city."},
                {max: 50, message: "Maximum 50 characters allowed."},
              ]}
            >
              <Input placeholder="City" style={{height: "54px"}}/>
            </Form.Item>

            <Form.Item
              name="music"
              rules={[
                {required: true, message: "Please enter your preferred music."},
                {max: 255, message: "Maximum 255 characters allowed."},
              ]}
            >
              <Input placeholder="What song would you like playing when you enter the arena ?" />
            </Form.Item>

            <Form.Item label="Have you participated in any previous TFA/Stair Run Challenges in the past? If yes, please list below:" name="experiece" onChange={handleRadioChange}>
              <Radio.Group>
                <Radio.Button value="yes">Yes</Radio.Button>
                <Radio.Button value="no">No</Radio.Button>
              </Radio.Group>
            </Form.Item>

            {experienceFields === "yes" ?
              <Form.List name="bio" initialValue={[
                { event_date: "", event_title: "", position: "" },
              ]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{display: 'flex', marginBottom: 8}} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, 'event_date']}
                          rules={[
                            {
                              required: true,
                              message: 'Missing event date',
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            disabledDate={disabledDate}
                            picker="year"
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'event_title']}
                          rules={[
                            {
                              required: true,
                              message: 'Missing event name',
                            },
                          ]}
                        >
                          <Input placeholder="Event Name" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'position']}
                          rules={[
                            {
                              required: true,
                              message: 'Missing position awarded',
                            },
                          ]}
                          style={{width: "250px"}}
                        >
                          <Input placeholder="Position Awarded 1st, 2nd etc.," />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add More
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              : <></>}

            <Form.Item>
              <FormButton
                onClick={() => handleContinue()}
                style={{marginTop: 15}}
              >
                Continue <ArrowElment/>
              </FormButton>
            </Form.Item>
          </Form>
        </div>
      </FormContent>
    </Container>
  );
};
export default CompetitorInformation;
