import styled from "styled-components";

const PageTitleHeader = styled.h1`
  color: #141414;
  text-align: center;
  font-size: 48px;
  font-style: italic;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1.92px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;

  svg {
    margin-left: 6px;
  }

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 32px;
  }
`;

const PageTitleContainer = styled.div`
  margin: 50px 0;

  @media (max-width: 992px) {
    margin: 24px 0;
  }
`;

export const PageTitle = ({ title, description }) => {
  return (
    <PageTitleContainer>
      <PageTitleHeader>
        {title}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="39"
          viewBox="0 0 27 39"
          fill="none"
        >
          <path
            d="M7.33218 3L21.0858 18.8836L2.71096 35.6748"
            stroke="#FF4119"
            strokeWidth="8"
          />
        </svg>
      </PageTitleHeader>
      {description && (
        <span className="text-[#7A7986] text-lg leading-[32px] m-auto">
          Please fill the fields carefully
        </span>
      )}
    </PageTitleContainer>
  );
};
