import {Fragment} from "react";
import {
  Header,
  Description,
  DescriptionContainer,
  Image,
  ImageContainer,
} from "./style";
import { FButton, ButtonContainer } from "../shared/style";
import Footer from "../layout/footer";
import Navbar from "../layout/header";
import { Navigate, useNavigate } from "react-router-dom";
import ArrowElment from "../shared/arrow";
import WelcomeImg from "../../images/welcome.png";
import { Container } from "../register/style";

const Home = () => {
  const navigate = useNavigate();

  const register = () => {
    navigate("/register");
  };
  let isLoggedIn = JSON.parse(localStorage.getItem("user_data"));
  if (!isLoggedIn?.access_token) {
    return <Navigate to={"/login"} replace={true} />;
  }
  return (
    <Fragment>
      <Navbar />
      <Header className="mt-20 mb-10">Welcome to the<br />Aramco Firefighter Challenge 2024 (AFC24)<br />Registration Portal</Header>
      <Container>
        <DescriptionContainer>
          <Description>
            Get ready to showcase your abilities and embrace the thrill of
            competition. We believe in your potential and can't wait to witness
            your excellence.
          </Description>
        </DescriptionContainer>

        <section className="note-par" style={{marginTop: 60}}>
          <div className="dark-note">
            <h5 className="note" style={{color: "#fff"}}>Important note to all competitors:</h5>
            <h3 style={{fontSize: 17, lineHeight: "27px", marginTop: "8px", marginBottom: "15px", color: "#fff"}}>
              Please be advised that your tourist visa you require to enter Saudi Arabia will be at your own expense.
            </h3>
            <li>
              To apply for a tourist visa for Saudi Arabia please go to{" "}
              <span>
                  <a
                    href={"https://visa.visitsaudi.com"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://visa.visitsaudi.com
                  </a>
                </span>
            </li>
            <div className="warning">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                fill="none"
                viewBox="0 0 21 21"
              >
                <path
                  fill="#FF4119"
                  fillRule="evenodd"
                  d="M10.5 2C5.813 2 2 5.813 2 10.5c0 4.687 3.813 8.5 8.5 8.5 4.687 0 8.5-3.813 8.5-8.5C19 5.813 15.187 2 10.5 2zm0 18.5c-5.514 0-10-4.486-10-10s4.486-10 10-10 10 4.486 10 10-4.486 10-10 10z"
                  clipRule="evenodd"
                ></path>
                <path
                  fill="#FF4119"
                  fillRule="evenodd"
                  d="M10.494 11.873a.75.75 0 01-.75-.75V6.704a.75.75 0 011.5 0v4.419a.75.75 0 01-.75.75zM10.504 15.296a1.003 1.003 0 01-1.005-1c0-.553.443-1 .995-1h.01a1 1 0 110 2z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>
                  Please ensure you obtain a visitors’ visa for Saudi Arabia via
                  the eVisa Saudi Arabia portal as soon as possible in order for
                  the AFC24 organizing committee to process flights and
                  accommodation.
                </span>
            </div>
          </div>
        </section>

        <section className="note-par" style={{marginTop: 0, textAlign: "left"}}>
          <div className="dark-note" style={{padding: 0, background: "none", width: "auto", marginBottom: 10, textAlign: "left"}}>
            <div className="warning" style={{width: "auto", justifyContent: "left"}}>
              <span>
                  To complete the registration process in this portal you will need the following documentation in PDF<br/>
format:<br/>
. eVisa<br/>
. Passport details page<br/>
. Fire Department I.D. or letter confirming employment<br/>
. Fire Truck Driver’s license (if participating in the Fire Truck Challenge)
                </span>
            </div>
          </div>
        </section>

        <ButtonContainer>
          <FButton onClick={register}>
            Register <ArrowElment/>
          </FButton>
        </ButtonContainer>
        <ImageContainer style={{textAlign: "center"}}>
          <Image src={WelcomeImg} alt=""/>
        </ImageContainer>
      </Container>
      <Footer/>
    </Fragment>
  );
};
export default Home;
