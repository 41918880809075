import fireLogo from "../../../images/footer_logo.png";
import { Row, Col } from "antd";
import { Container } from "../../register/style";
const Footer = () => {
  return (
    <Container className="border-t border-[#E4E4E4]">
      <footer className="py-8 md:py-16 overflow-hidden">
        <Row
          justify="center"
          align="middle"
          gutter={[16, 16]}
          style={{ marginBottom: "20px" }}
        >
          {" "}
          <Col xs={24}>
            <img
              src={fireLogo}
              alt="Aramco Firefighter Challenge logo"
              className="m-auto"
            />
          </Col>
        </Row>
        <Row
          justify="center"
          align="middle"
          gutter={[16, 16]}
          style={{ marginBottom: "20px" }}
        >
          <Col xs={24}>
            <div className="text-[#7A7986] text-[14px] leading-5 text-center">
              <span>
                Copyright © 2024 Aramco Firefighter Challenge. All Rights Reserved.
              </span>
            </div>
          </Col>
        </Row>
        <Row justify="center" align="middle" gutter={[16, 16]}>
          <div className="flex items-center gap-4 m-auto">
            <span className="bg-[#FF4119] p-[10px] rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  d="M12.5 10.5717C12.5 11.0661 12.3534 11.5495 12.0787 11.9606C11.804 12.3717 11.4135 12.6921 10.9567 12.8814C10.4999 13.0706 9.99723 13.1201 9.51227 13.0236C9.02732 12.9272 8.58186 12.6891 8.23223 12.3394C7.8826 11.9898 7.6445 11.5443 7.54804 11.0594C7.45157 10.5744 7.50108 10.0718 7.6903 9.61495C7.87952 9.15813 8.19995 8.76768 8.61107 8.49298C9.0222 8.21828 9.50555 8.07166 10 8.07166C10.6628 8.07241 11.2983 8.33605 11.7669 8.80473C12.2356 9.2734 12.4992 9.90885 12.5 10.5717ZM17.8125 7.13416V14.0092C17.8112 15.1691 17.3498 16.2811 16.5296 17.1013C15.7095 17.9215 14.5974 18.3828 13.4375 18.3842H6.5625C5.40258 18.3828 4.29055 17.9215 3.47036 17.1013C2.65017 16.2811 2.18881 15.1691 2.1875 14.0092V7.13416C2.18881 5.97424 2.65017 4.8622 3.47036 4.04201C4.29055 3.22183 5.40258 2.76047 6.5625 2.75916H13.4375C14.5974 2.76047 15.7095 3.22183 16.5296 4.04201C17.3498 4.8622 17.8112 5.97424 17.8125 7.13416ZM13.75 10.5717C13.75 9.82998 13.5301 9.10495 13.118 8.48827C12.706 7.87158 12.1203 7.39094 11.4351 7.10711C10.7498 6.82328 9.99584 6.74902 9.26841 6.89371C8.54098 7.03841 7.8728 7.39556 7.34835 7.92C6.8239 8.44445 6.46675 9.11264 6.32206 9.84007C6.17736 10.5675 6.25162 11.3215 6.53545 12.0067C6.81928 12.6919 7.29993 13.2776 7.91661 13.6897C8.5333 14.1017 9.25832 14.3217 10 14.3217C10.9942 14.3205 11.9474 13.9251 12.6504 13.2221C13.3534 12.519 13.7489 11.5659 13.75 10.5717ZM15 6.50916C15 6.32374 14.945 6.14248 14.842 5.98831C14.739 5.83414 14.5926 5.71398 14.4213 5.64302C14.25 5.57206 14.0615 5.5535 13.8796 5.58967C13.6977 5.62584 13.5307 5.71513 13.3996 5.84624C13.2685 5.97735 13.1792 6.1444 13.143 6.32626C13.1068 6.50812 13.1254 6.69662 13.1964 6.86792C13.2673 7.03923 13.3875 7.18564 13.5417 7.28866C13.6958 7.39167 13.8771 7.44666 14.0625 7.44666C14.3111 7.44666 14.5496 7.34788 14.7254 7.17207C14.9012 6.99625 15 6.7578 15 6.50916Z"
                  fill="white"
                />
              </svg>
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="41"
              viewBox="0 0 40 41"
              fill="none"
            >
              <path
                d="M20.0497 39.2379C30.454 39.2379 38.8884 30.8035 38.8884 20.3992C38.8884 9.99482 30.454 1.56042 20.0497 1.56042C9.64533 1.56042 1.21094 9.99482 1.21094 20.3992C1.21094 30.8035 9.64533 39.2379 20.0497 39.2379Z"
                fill="#FF4119"
              />
              <path
                d="M11.3609 12.2274L18.1029 21.242L11.3184 28.5713H12.8454L18.7854 22.1542L23.5846 28.5713H28.7808L21.6593 19.0498L27.9743 12.2274H26.4473L20.9771 18.1372L16.5571 12.2274H11.3609ZM13.6064 13.3521H15.9936L26.5349 27.4466H24.1478L13.6064 13.3521Z"
                fill="white"
              />
            </svg>
            <span className="bg-[#FF4119] p-[10px] rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  d="M13.7507 5.57166H11.2507C10.7904 5.57166 10.4173 5.94476 10.4173 6.40499V8.90499H13.7507C13.8454 8.90289 13.9353 8.94699 13.9917 9.02324C14.048 9.09949 14.0638 9.19832 14.034 9.28832L13.4173 11.1217C13.3605 11.2899 13.2032 11.4037 13.0257 11.405H10.4173V17.655C10.4173 17.8851 10.2307 18.0717 10.0007 18.0717H7.91732C7.6872 18.0717 7.50065 17.8851 7.50065 17.655V11.405H6.25065C6.02053 11.405 5.83398 11.2184 5.83398 10.9883V9.32166C5.83398 9.09157 6.02053 8.90499 6.25065 8.90499H7.50065V6.40499C7.50065 4.56404 8.99307 3.07166 10.834 3.07166H13.7507C13.9807 3.07166 14.1673 3.25821 14.1673 3.48832V5.15499C14.1673 5.38511 13.9807 5.57166 13.7507 5.57166Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
        </Row>
      </footer>
    </Container>
  );
};

export default Footer;
